<template>
  <section class="grid gap-1">
    <!-- Header -->
    <header class="is-flex align-end justify-between sticks-to-navbar">
      <div>
        <h3 class="is-size-4 has-text-primary">{{ Naming.User }} Access</h3>
        <p>Select which admins and {{ Naming.Technicians.toLowerCase() }} may access this {{ Naming.Site.toLowerCase() }}:</p>
      </div>
      <form class="flex-grow plr-2">
        <text-input
            class="is-marginless is-rounded"
            classes="is-rounded"
            left-icon="search"
            v-model="filters.search"
            :working="filtering"
            :placeholder="`Filter ${Naming.Users.toLowerCase()} by name`"
            :label="false">
            <action-button
                v-if="filters.search"
                slot="right"
                class=""
                @click="clearFilter('search')">
              <icon icon="times"/>
            </action-button>
            <action-button
                slot="right"
                @click="runFilters"
                :disabled="filtering"
                class="is-rounded">
              Search
            </action-button>
        </text-input>
      </form>
      <div class="buttons">
        <action-button
            @click="selectAllUsers"
            class="is-rounded is-success is-inverted">
          Select All
        </action-button>
        <action-button
            :disabled="!deselectAllIsEnabled"
            @click="deselectAllUsers"
            class="is-rounded is-danger is-inverted">
          Deselect All
        </action-button>
        <action-button
            @click="syncUsers"
            :working="syncing"
            class="is-rounded is-success has-text-weight-bold"
            left-icon="save">
          Save Changes
        </action-button>
      </div>
    </header>

    <!-- User Access List -->
    <article class="box p-1">
      <loader v-bind="{ loading }">
        <template v-if="userList.length">
          <div class="grid has-2-columns column-gap-1">
            <a v-for="user in userList" :key="user.id"
               @click="toggleUser(user)"
               class="is-flex align-center selectable-item"
               :class="{ 'is-selected': userHasAccessToSite(user) }">
              <icon
                  class="mr-1"
                  icon="check-circle"
                  :type="userHasAccessToSite(user) ? 'fas' : 'far'"/>
              <div class="is-flex flex-grow align-center">
                <span class="has-text-weight-medium"
                      :class="{ 'has-text-grey': !userHasAccessToSite(user) }">{{ user.full_name }}</span>
                <span class="tag ml has-text-info has-text-right flex-shrink-none" v-if="userIsAdmin(user)">admin</span>
              </div>
            </a>
          </div>
        </template>
        <no-data v-else
                 :title="`No ${Naming.Users}`"
                 :description="`There are no active ${Naming.Users.toLowerCase()} available on your account.`"/>
      </loader>
    </article>
  </section>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import {filtering} from '@/mixins'

import findIndex from 'lodash/findIndex'

export default {

  mixins: [
    filtering
  ],

  data: () => ({
    loading: true,
    filtering: false,
    syncing: false,
    filters: {
      search: ''
    }
  }),

  computed: {
    ...mapState('site', ['site', 'siteUsers']),
    ...mapState('user', ['userList']),

    userIsAdmin() {
      return user => findIndex(user.roles, role => role.name === 'admin') >= 0
    },

    deselectAllIsEnabled() {
      return this.siteUsers?.length > 0
    },
  },

  async beforeMount() {
    await Promise.all([
      this.loadUsers(),
      this.loadSiteUsers(),
    ])
    this.loading = false
  },

  methods: {
    ...mapMutations('site', ['toggleUser']),

    async loadUsers() {
      return await this.$store.dispatch('user/loadActiveUserList', this.$route.query)
    },

    async loadSiteUsers() {
      return await this.$store.dispatch('site/loadSiteUsers', this.$route.params.site)
    },

    async reloadUsers() {
      this.filtering = true
      await this.loadUsers()
      this.filtering = false
    },

    userHasAccessToSite(user) {
      return findIndex(this.siteUsers, {id: user.id}) >= 0
    },

    deselectAllUsers(){
      this.$store.commit('site/clearUsers', this.userList)
    },

    selectAllUsers(){
      this.$store.commit('site/selectAllUsers', this.userList)
    },

    async syncUsers() {
      this.syncing = true
      this.$store
          .dispatch('site/syncUsers')
          .then(() => {
            this.$toast.success(this.Convert('User access updated'))
          })
          .catch((error) => {
            if (error.response.status === 406) {
              this.$alert('Notice', error.response.data)
            } else {
              this.$whoops()
            }
          })
          .finally(async () => {
            await this.$store.dispatch('site/loadSiteUsers', this.$route.params.site)
            this.syncing = false
          })
    },
  },

  watch: {
    $route: 'reloadUsers',
  }

}
</script>
