<template>
<loader v-bind="{ loading }">
  <div class="box pl-2">
    <level :title="`Edit ${Naming.User}`"></level>

    <form>
      <div class="columns">
        <div class="column">
          <text-input :error="$root.errors.name" required :value="user.name" @input="name">First Name</text-input>
        </div>
        <div class="column">
          <text-input :error="$root.errors.last_name" required :value="user.last_name" @input="lastName">Last Name</text-input>
        </div>
        <div class="column">
          <text-input :error="$root.errors.email" required :value="user.email" @input="email">Email</text-input>
        </div>
        <div class="column is-3">
          <intl-phone
            v-if="!loading"
            :error="$root.errors.profile"
            :number="user.profile.mobile"
            :code="user.profile.dialing_code"
            @code="dialingCode"
            @number="mobile"/>
        </div>
        <div class="column is-narrow">
          <text-input :error="$root.errors['profile.position']" required :value="user.profile.position" @input="position">Position</text-input>
        </div>
        <div class="column is-narrow">
          <checkbox-input classes="is-large" :value="user.active" @input="toggleActive">Active</checkbox-input>
        </div>
      </div>


      <label class="label is-field-label"><div class="main-label"><span>Change {{ Naming.User }} Password</span></div></label>
      <div class="columns">

        <div class="column is-narrow">
          <password-input
            :label="false"
            meter
            placeholder="New Password"
            :error="$root.errors.password"
            v-model="reset.password"
          />
        </div>
        <div class="column is-narrow">
          <password-input
            :label="false"
            placeholder="Confirm New Password"
            left-icon="asterisk"
            :error="$root.errors.password_confirmation"
            v-model="reset.password_confirmation"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column has-text-left">
          <submit-button
            :working="working"
            @submit="resetPassword"
          >Change Password</submit-button>
        </div>
      </div>

      <div class="mb-1">
        <data-selector
            :description="`Select the applicable timezone for the ${Naming.User.toLowerCase()}`"
            required
            :error="$root.errors.timezone"
            :value="user.timezone"
            :items="timezones"
            @input="updateTimezone"
            searchable>
            Timezone
        </data-selector>
      </div>

      <div class="mb-2">
        <client-user-site-access 
          :siteList="siteList"
          :userClientSites="user.client_sites"
          :clientId="clientId">
        </client-user-site-access>
      </div>
      <div>
        <h4 class="title is-4 has-text-weight-light">Notification Channels</h4>
        <p class="mb-1">By default notifications are turned off. Please select any applicable notification channels below.</p>

        <div class="columns" v-for="(channels, group) in groupedNotificationChannels" :key="group">
          <div class="column">
            <h4 class="title is-5 has-text-primary">{{ group }}</h4>
            <div class="grid gap-1">
              <notification-channel-check
                v-for="channel in channels"
                :key="channel.id"
                v-bind="{ channel }"/>
            </div>
          </div>
        </div>
      </div>

      <div class="columns mt-1">
        <div class="column">
          <submit-button :working="working" @submit="save" class="is-success" left-icon="check">Save</submit-button>
        </div>
      </div>
    </form>
  </div>
</loader>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ClientUserSiteAccess from '../partials/ClientUserSiteAccess';

export default {

  components: {
    ClientUserSiteAccess
  },

  data: () => ({
    working: false,
    loading: true,
    reset: {
      password: "",
      password_confirmation: ""
    },
  }),

  beforeCreate() {
    this.$store.commit('user/clear')
  },

  created() {
    Promise.all([
      this.$store.dispatch('site/loadClientSiteList', this.$route.params.client),
      this.$store.dispatch('company/loadSubscribableNotificationTypesByUser', this.$route.params.user),
      this.$store.dispatch('user/loadUser', this.$route.params.user),
      this.$store.dispatch('list/loadTimezones')
    ]).then(() => {
      this.loading = false
    }).catch(() => {
      this.loading = false
    })

  },

  methods: {
    ...mapMutations('user', [
      'name',
      'lastName',
      'email',
      'password',
      'passwordConfirmation',
      'roles',
      'mobile',
      'position',
      'clientSites',
      'toggleActive',
      'dialingCode',
      'updateTimezone'
    ]),
    toggleChannel(channelId) {
      this.$store.commit('user/toggleSubscription', channelId)
    },
    save() {
      if (this.user.client_sites.length === 0) {
        this.$toast.error('Please select at least one site.');
        return;
      }
      this.working = true
      this.$store.dispatch('user/updateClientUser', this.$route.params.client).then(response => {
        this.working = false
        this.$toast.success(this.Convert(this.$lang.user.saved))
      }).catch(error => {
        this.working = false
      })
    },

    async resetPassword() {
      if (
        await this.$confirm({
          title: this.Convert("Change User Password"),
          message: this.Convert("Are you sure you want to change this users password?")
        })
      ) {
        this.working = true;
        this.$store
          .dispatch("user/resetPassword", this.reset)
          .then(response => {
            this.$toast.success("Saved");
            this.working = false;
            this.reset = {
              password: "",
              password_confirmation: ""
            };
          })
          .catch(error => {
            this.$toast.error("Oops, something went wrong please try again");
            this.working = false;
          });
      }
    },
  },

  computed: {
    ...mapGetters('user', [
      'user'
    ]),
    ...mapGetters('site', [
      'siteList'
    ]),
    ...mapGetters('company', [
      'groupedNotificationChannels'
    ]),
    ...mapGetters('list', [
      'timezones'
    ]),
    clientId() {
      return this.$route.params.client.toString() 
    },
  }

}
</script>
