<template>
  <loader v-bind="{ loading }" text="Loading Report">

    <div class="is-flex align-center justify-between mb-1">
      <h1 class="is-size-4">{{ `${Naming.Inspection} #${inspection.id} Overview` }}</h1>
      <div class="buttons has-addons is-rounded is-right">
        <router-link
            class="button is-white is-rounded"
            :to="{
          name: 'job-handler',
          params: {
            job: inspection.job_id
          }
        }">
          <icon icon="arrow-left"/>
          <span>{{ Naming.Job }} #{{ inspection.job.number }}</span>
        </router-link>

        <dropdown-wrapper position="bottom-end">
          <action-button
              slot="trigger"
              class="is-info"
              :working="downloadingInspection"
              left-icon="download">
            Download Report
          </action-button>

          <dropdown-item @click="downloadInspectionReport(inspection, 'pdf')">
            Portable Document Format (PDF)
          </dropdown-item>
          <dropdown-item @click="downloadInspectionReport(inspection, 'xlsx')">
            Excel Workbook (XLSX)
          </dropdown-item>
          <dropdown-item @click="downloadInspectionReport(inspection, 'csv')">
            Comma-Separated Values (CSV)
          </dropdown-item>
          <dropdown-item @click="downloadInspectionSummaryReport(inspection)">
            {{ Naming.Inspection }} Summary (PDF)
          </dropdown-item>
          <dropdown-item @click="downloadInspectionReport(inspection, 'zip')">
            Images only (.zip)
          </dropdown-item>
        </dropdown-wrapper>
        <action-button @click="deleteInspection" class="is-rounded is-danger"
                       v-if="hasAbility('Billow\\Models\\Inspection', 'delete-inspections')">
          <icon icon="trash"/>
        </action-button>
      </div>
    </div>

    <div class="box p-2">
      <div class="columns">
        <div class="column is-4">
          <split-view>
            <p slot="left" class="has-text-weight-light">{{ Naming.Job }}</p>
            <router-link
                class="has-text-link"
                slot="right"
                :to="{
              name: 'job-handler',
              params: {
                job: inspection.job_id
              }
            }">
              <span>#{{ inspection.job.number }}</span>
            </router-link>
          </split-view>
          <split-view
              class="is-marginless is-paddingless is-gapless"
              left-classes="is-narrow"
              right-classes="has-text-right">
            <p slot="left" class="has-text-weight-light">{{ Naming.Site }}</p>
            <router-link
                class="has-text-link"
                slot="right"
                :to="{
              name: 'site-overview',
              params: {
                site: inspection.job.site_id
              }
            }">
              <span>{{ inspection.job.site.name }}</span>
            </router-link>
          </split-view>
          <split-view
              class="is-marginless is-paddingless is-gapless"
              left-classes="is-narrow"
              right-classes="has-text-right">
            <p slot="left" class="has-text-weight-light">{{ Naming.Checklist }}</p>
            <p slot="right" class="title is-6 has-text-weight-bold">
              {{ inspection.checklist ? inspection.checklist.name : '-' }}</p>
          </split-view>
          <split-view
              class="is-marginless is-paddingless is-gapless"
              left-classes="is-narrow"
              right-classes="has-text-right">
            <p slot="left" class="has-text-weight-light">{{ Naming.Technician }}</p>
            <p slot="right" class="title is-6 has-text-weight-bold" v-if="inspection.technician">
              {{ inspection.technician.full_name }}
            </p>
          </split-view>
          <split-view
              class="is-marginless is-paddingless is-gapless"
              left-classes="is-narrow"
              right-classes="has-text-right">
            <p slot="left" class="has-text-weight-light">{{ Naming.Zone }}</p>
            <p slot="right" class="title is-6 has-text-weight-bold" v-if="asset.zone">{{ asset.zone.name }}</p>
          </split-view>
          <split-view
              class="is-marginless is-paddingless is-gapless"
              left-classes="is-narrow"
              right-classes="has-text-right">
            <p slot="left" class="has-text-weight-light">{{ Naming.Asset }} Internal ID</p>
            <p slot="right" class="title is-6 has-text-weight-bold">
              {{ asset.code }}</p>
          </split-view>
          <split-view
              class="is-marginless is-paddingless is-gapless"
              left-classes="is-narrow"
              right-classes="has-text-right">
            <p slot="left" class="has-text-weight-light">Barcode</p>
            <p slot="right" class="title is-6 has-text-weight-bold">
              {{ asset.barcode.data }}</p>
          </split-view>
          <split-view
              class="is-marginless is-paddingless is-gapless"
              left-classes="is-narrow"
              right-classes="has-text-right">
            <p slot="left" class="has-text-weight-light">{{ Naming.AssetType }}</p>
            <p slot="right" class="title is-6 has-text-weight-bold">
              {{ asset.type.name }}</p>
          </split-view>
        </div>
        <div class="column is-4 has-text-weight-light">
          <split-view
              class="is-marginless is-paddingless is-gapless"
              left-classes="is-narrow"
              right-classes="has-text-right">
            <p slot="left" class="has-text-weight-light">Results</p>
            <p slot="right" class="title is-6 has-text-weight-bold">{{ inspectionResults }}</p>
          </split-view>
          <split-view
              class="is-marginless is-paddingless is-gapless"
              left-classes="is-narrow"
              right-classes="has-text-right">
            <p slot="left" class="has-text-weight-light">Started</p>
            <p slot="right" class="title is-6 has-text-weight-bold">{{ inspection.created_at | date('L LTS') }}</p>
          </split-view>
          <split-view
              class="is-marginless is-paddingless is-gapless"
              left-classes="is-narrow"
              right-classes="has-text-right">
            <p slot="left" class="has-text-weight-light">Completed</p>
            <p slot="right" class="title is-6 has-text-weight-bold" v-if="inspection.completed_at">
             ({{ duration }}) {{ inspection.completed_at | date('L LTS') }}
            </p>
          </split-view>
          <split-view
              class="is-marginless is-paddingless is-gapless"
              left-classes="is-narrow"
              right-classes="has-text-right">
            <p slot="left" class="has-text-weight-light">Duration</p>
            <p slot="right" class="title is-6 has-text-weight-bold" v-if="inspection.completed_at">
              {{ '(' + duration + ') ' }}
            </p>
          </split-view>
        </div>
        <div class="column has-text-right">
          <p class="is-size-3 has-text-weight-light is-flex align-center justify-end">
            <warning-triangle class="has-text-danger" v-if="inspectionOutCome === 'Failed'"/>
            <check-circle class="has-text-success" v-if="inspectionOutCome === 'Passed'"/>
            <span class="ml-1">{{ inspectionOutCome }}</span>
          </p>
        </div>
      </div>

      <div class="columns" v-if="inspection.location && inspection.location.latitude !== null">
        <div class="column">
          <p class="is-size-5 has-text-weight-light mb-1">Location</p>
          <GmapMap
              :center="{ lat: inspection.location.latitude, lng: inspection.location.longitude }"
              :zoom="16"
              :options="googleMapOptions"
              map-type-id="terrain"
              style="width: 100%; height: 300px">
            <GmapMarker :position="{ lat: inspection.location.latitude, lng: inspection.location.longitude }"/>
          </GmapMap>
          <p class="has-text-centered mt">
            {{ 'lat: ' + inspection.location.latitude + ' - lng: ' + inspection.location.longitude }}
          </p>
        </div>
      </div>

      <!--Asset Custom Fields-->
      <div class="columns" v-if="assetCustomFields.length > 0">
        <div class="column">
          <p class="is-size-5 has-text-weight-light mb-1">{{ Naming.CustomFields }}</p>
          <table class="table is-fullwidth">
            <thead>
            <tr>
              <th>Field Name</th>
              <th class="has-text-right">Field Value</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(field, index) in assetCustomFields" :key="index">
              <td>
                {{ field.label }}
              </td>
              <td class="has-text-right">
                {{ field.value }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <p class="is-size-5 has-text-weight-light mb-1">{{ Naming.Checklist }}</p>
          <table class="table is-fullwidth">
            <thead>
            <tr>
              <th>{{ Naming.Check }}</th>
              <th class="is-narrow has-text-right">Outcome</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(answer, index) in sortedInspectionAnswers" :key="answer.id">
              <td>
                <strong class="pointer" @click="editComment(answer)">{{ index + 1 + '.' }} {{
                    answer.check.name
                  }}</strong>
                <span v-if="answer.check.instant_fail && answer.answer === 0"
                      class="ml tag is-danger">instant fail</span>
                <div style="width: 80%;">
                  <p class="ml-1 has-text-weight-light">{{ answer.check.task }}</p>
                </div>

                <div class="columns is-multiline ml" style="margin-top: 3px"
                     v-if="inspection.checklist.inspection_report_images_inline">
                  <div class="column is-3" v-for="image in answer.evidenceList" :key="image.id">
                    <figure class="image is-16by9">
                      <img class="cursor-pointer object-fit-cover" @click="openImage(image.image_url)"
                           :src="image.image_url">
                    </figure>
                    <div class="has-background-cool-blue p-1 is-size-7">{{ image.description }}</div>
                  </div>
                </div>

                <div class="ml-1" v-if="answer.comment">
                  <p>
                    <strong @click="showChanges(answer)" :class="{
                      'pointer': hasChanges(answer.comment)
                    }">Comments <span v-if="hasChanges(answer.comment)">({{
                        changeCount(answer.comment)
                      }})</span></strong>
                    <br>
                    {{ answer.comment.text }}
                  </p>

                </div>

              </td>
              <td>
                <span v-if="answer.answer === 1" class="is-flex justify-between align-center">
                  <icon class="has-text-success" icon="check-circle lg"/>
                  <span>Passed</span>
                </span>
                <span v-if="answer.answer === 0" class="is-flex justify-between align-center">
                  <icon class="has-text-danger" icon="times-circle lg"/>
                  <span>Failed</span>
                </span>
                <span v-if="answer.answer === -1" class="is-flex justify-between align-center">
                  <icon class="has-text-warning" icon="dot-circle lg"/>
                  <span>N/A</span>
                </span>
              </td>
            </tr>
            </tbody>
          </table>

          <p class="has-text-centered" v-if="!inspection.answers.length">No Failed {{ Naming.Checks }}</p>
        </div>
      </div>

      <div class="evidence-section" v-if="!inspection.checklist.inspection_report_images_inline">
        <div class="columns" v-if="inspection.evidence && inspection.evidence.length">
          <div class="column">
            <p class="is-size-5 has-text-weight-light">Evidence</p>
          </div>
        </div>

        <div class="columns is-multiline">
          <div class="column is-3" v-for="image in inspection.evidence" :key="image.id">
            <figure class="image is-16by9">
              <img class="cursor-pointer object-fit-cover" @click="openImage(image.image_url)" :src="image.image_url">
            </figure>
            <div class="has-background-cool-blue p-1 is-size-7">{{ image.description }}</div>
          </div>
        </div>
      </div>

      <div class="columns" v-if="inspection.comments.length">
        <div class="column">
          <h3 class="title is-5">Additional Notes</h3>
        </div>
      </div>

      <div v-for="comment in inspection.comments" :key="comment.id" class="is-flex align-center">
        <avatar :path="comment.user.profile.picture_url" :name="comment.user.full_name" class="mr-1 mb-1"/>
        <div class="mb-1">
          <p class="has-text-weight-bold">{{ comment.user.full_name }}</p>
          <p>{{ comment.text }}</p>
        </div>
      </div>
    </div>
  </loader>

</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import download from 'downloadjs'
import {job as backend} from '@/api'
import {commentChanges, editAnswerComment, inspectionSummaryReport, viewImage} from '@/modals'
import defaultMapStyles from '@/theme/gmaps/default'
import {find, orderBy} from 'lodash'

export default {

  data: () => ({
    downloadingInspection: false,
    loading: true,
    googleMapOptions: {
      styles: defaultMapStyles,
    },
  }),

  beforeMount() {
    this.$store.dispatch('inspection/load', this.$route.params.inspection).then(() => {
      this.loading = false
    })
  },

  beforeDestroy() {
    this.clear()
  },

  methods: {
    ...mapMutations('inspection', [
      'clear',
    ]),
    getOutcome(answer) {
      return answer.answer !== 0
    },

    downloadInspectionSummaryReport(inspection) {
      this.$store.commit('inspection/setInspection', inspection)
      inspectionSummaryReport().then()
    },
    downloadInspectionReport(inspection, type) {
      this.downloadingInspection = true

      if (type === 'pdf') {
        backend.downloadInspectionReport({
          job: inspection.job_id,
          inspection: inspection.id,
        }, ({data}) => {
          this.downloadingInspection = false
          download(data,
              `${this.Convert('Inspection')} ${this.inspection.id} - ${this.Convert('Job')} #${this.inspection.job.number} - ${this.asset.code} - ${this.inspection.checklist.name} - ${this.inspection.job.site.name}.pdf`,
              'application/pdf')
        }, error => {
          this.$whoops()
          this.downloadingInspection = false
        })
      } else if (type === 'xlsx') {
        backend.downloadInspectionReportAsFile({
          type,
          inspection: inspection.id,
        }, ({data}) => {
          this.downloadingInspection = false
          download(
              data,
              `${this.Convert('Inspection')} ${this.inspection.id} - ${this.Convert('Job')} #${this.inspection.job.number} - ${this.asset.code} - ${this.inspection.checklist.name} - ${this.inspection.job.site.name}.${type}`,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          )
        }, error => {
          this.$whoops()
          this.downloadingInspection = false
        })
      } else if (type === 'csv') {
        backend.downloadInspectionReportAsFile({
          type,
          job: inspection.job_id,
          inspection: inspection.id,
        }, ({data}) => {
          this.downloadingInspection = false
          download(
              data,
              `${this.Convert('Inspection')} ${this.inspection.id} - ${this.Convert('Job')} #${this.inspection.job.number} - ${this.asset.code} - ${this.inspection.checklist.name} - ${this.inspection.job.site.name}.${type}`,
              'text/csv',
          )
        }, error => {
          this.$whoops()
          this.downloadingInspection = false
        })
      } else if (type === 'zip') {
        backend.downloadInspectionEvidence({
          type,
          job: inspection.job_id,
          inspection: inspection.id,
        }, ({data}) => {
          this.downloadingInspection = false
          download(
              data,
              `${this.Convert('Inspection')} ${this.inspection.id} - ${this.Convert('Job')} #${this.inspection.job.number} - ${this.asset.code} - ${this.inspection.checklist.name} - ${this.inspection.job.site.name}.${type}`,
              'blob',
          )
        }, error => {
          this.$whoops()
          this.downloadingInspection = false
        })
      }
    },
    openImage(path) {
      viewImage(path)
    },
    async deleteInspection() {
      if (await this.$confirm({
        title: this.Convert('Delete Inspection'),
        message: this.Convert('Are you sure you want to delete this inspection?'),
      })) {
        this.$store.dispatch('inspection/deleteInspection', this.inspection.id).then(() => {
          this.$router.back()
        })
      }
    },
    hasAbility(entityType, name) {
      return this.user.abilities.filter(ability => ability.entity_type === entityType && ability.name === name).length >
          0
    },
    editComment(answer) {
      editAnswerComment(answer).then(() => {
        this.$store.dispatch('inspection/load', this.$route.params.inspection).then(() => {
          this.loading = false
        })
      })
    },
    hasChanges(comment) {
      if (!comment) return false
      return comment.audits.filter(audit => audit.event === 'updated').length > 0
    },
    changeCount(comment) {
      if (!comment) return 0
      return comment.audits.filter(audit => audit.event === 'updated').length
    },
    showChanges(answer) {
      if (!answer.comment) return
      commentChanges(answer)
    },
    sortBy(iterable) {

      return orderBy(iterable, answer => {
        let checkpoint = find(this.inspection.checklist.checks, check => check.id === answer.check_id)
        if (!checkpoint || !checkpoint.pivot) return 'end'
        return checkpoint.pivot.sort
      })
    },
  },

  computed: {
    ...mapGetters('inspection', [
      'inspection',
    ]),
    ...mapGetters('asset', [
      'asset',
    ]),
    ...mapGetters('auth', [
      'user',
    ]),
    defectiveAnswers() {
      return this.inspection.answers.filter(answer => answer.answer === 0)
    },
    inspectionOutCome() {
      if (this.inspection.passed_at && !this.inspection.failed_at) return 'Passed'
      if (!this.inspection.passed_at && this.inspection.failed_at) return 'Failed'
      if (!this.inspection.passed_at && !this.inspection.failed_at) return 'In Progress'
    },
    inspectionResultIcon() {
      if (this.inspectionOutCome === 'Passed') return 'check-circle'
      if (this.inspectionOutCome === 'Failed') return 'times-circle'
      return 'clock'
    },
    inspectionResults() {
      return this.inspection.answers.filter(answer => answer.answer !== 0).length + '/' + this.inspection.answers.length
    },
    duration() {
      if (!this.inspection.completed_at) return null
      let start = moment(this.inspection.created_at)
      let end = moment(this.inspection.completed_at)
      let duration = moment.duration(end.diff(start))
      if (duration.asMinutes() > 60) {
        return duration.asHours().toFixed(0) + ' hours'
      }
      return duration.asMinutes().toFixed(0) + ' minutes'
    },

    assetCustomFields() {
      let customFields = this.asset.type.custom_fields_object
      let customFieldValues = this.asset.meta_data || []

      return _.map(customFields, field => {
        field.value = customFieldValues[field.field_id]

        return field
      })
    },
    sortedInspectionAnswers() {
      const evidenceList = this.inspection.evidence
      if (this.inspection.checklist === null) {
        return this.sortBy(this.inspection.answers)
      }
      const answers = this.inspection.answers.map((answer) => {
        const ev = evidenceList
            .filter((evidence) =>
                evidence.description.substring('Check: '.length) === answer.check.name)
        return {
          evidenceList: ev,
          ...answer
        }
      })

      return this.sortBy(answers)
    },
  },

}
</script>
