import { get, post, http } from './request'
import { utilities } from '@/mixins/js-helpers'

export default {
  loadClients: (path, success, failure) => {
    return get(
      path,
      response => success(response),
      error => failure(error)
    )
  },
  loadClientList: (success, failure) => {
    return get(
      '/api/clients/list',
      response => success(response),
      error => failure(error)
    )
  },
  loadDropdownClientList: (success, failure) => {
    return get(
      '/api/clients/dropdown-list',
      response => success(response),
      error => failure(error)
    )
  },
  loadClient: (clientId, success, failure) => {
    return get(
      `/api/client/${clientId}`,
      response => success(response),
      error => failure(error)
    )
  },
  searchClient: (search, success, failure) => get(
    `/api/clients/search`, success, failure, { params: search }
  ),
  recover: (clientId, success, failure) => {
    return post(
      `/api/client/${clientId}/recover`,
      {},
      response => success(response),
      error => failure(error)
    )
  },
  save: (client, success, failure) => {
    return post(
      `/api/client/${client.id}`,
      client,
      response => success(response),
      error => failure(error)
    )
  },
  delete: (clientId, success, failure) => {
    return post(
      `/api/client/${clientId}/delete`,
      {},
      response => success(response),
      error => failure(error)
    )
  },
  create: (client, success, failure) => {
    return post(
      `/api/client`,
      client,
      response => success(response),
      error => failure(error)
    )
  },
  uploadLogo: (payload, success, failure) => {
    return post(
      `/api/client/${payload.client}/logo`,
      utilities.formData({file: payload.file}),
      response => success(response),
      error => failure(error)
    )
  },
  resetClientLogo: (payload, success, failure) => {
    return post(
      `/api/client/${payload.client}/logo/reset`,
      {},
      response => success(response),
      error => failure(error)
    )
  },
  loadOverview: (clientId, success, failure) => {
    return get(
        `/api/client/${clientId}/overview-stats`,
        response => success(response),
        error => failure(error)
    )
  },
  loadOverviewJobStats: (clientId, success, failure) => {
    return get(
      `/api/client/${clientId}/overview-job-counts`,
      response => success(response),
      error => failure(error)
    )
  },
  loadOverviewLatestJobs: (clientId, success, failure) => {
    return get(
      `/api/client/${clientId}/overview-latest-jobs`,
      response => success(response),
      error => failure(error)
    )
  },
  downloadInspectionReportByDateRange: (payload, success, failure) => {
    return http({
        method: 'get',
        url: `/api/client/${payload.clientId}/inspectionreport`,
        params: payload,
        responseType: 'blob'
      }
    ).then(response => success(response))
    .catch(error => failure(error))
  },
}