<template>
  <loader v-bind="{ loading }">
    <form>
      <div class="columns">
        <div class="column">
          <div class="box">
            <h2 class="box-title">Basic Information</h2>
            <div class="columns">
              <div class="column">
                <text-input
                    :error="$root.errors.name"
                    required
                    :value="user.name"
                    @input="name"
                >First Name
                </text-input>
              </div>
              <div class="column">
                <text-input
                    :error="$root.errors.last_name"
                    required
                    :value="user.last_name"
                    @input="lastName"
                >Last Name
                </text-input>
              </div>
              <div class="column">
                <intl-phone
                    v-if="!loading"
                    :number="user.profile.mobile"
                    :code="user.profile.dialing_code"
                    @code="dialingCode"
                    @number="mobile"/>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <text-input
                    :value="user.profile.employee_number"
                    @input="updateEmployeeNumber">
                  Employee Number
                </text-input>
              </div>
              <div class="column">
                <text-input
                    :value="user.profile.id_number"
                    @input="updateIdNumber">
                  ID Number
                </text-input>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <text-input
                    :value="user.profile.certification"
                    @input="updateCertificationNumber">
                  Certification
                </text-input>
              </div>
              <div class="column">
                <data-selector
                    required
                    :error="$root.errors.timezone"
                    :value="user.timezone"
                    :items="timezones"
                    @input="updateTimezone"
                    searchable>
                  Timezone
                </data-selector>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <action-button
                    v-bind="{ working }"
                    @click="save"
                    class="is-success"
                    left-icon="save">
                  Save Basic Info
                </action-button>
              </div>
            </div>
          </div>

          <div class="box">
            <h2 class="box-title">Change Email Address</h2>

            <div class="columns">
              <div class="column">
                <text-input :value="user.email" disabled
                >Current Email
                </text-input>
              </div>
              <div class="column">
                <text-input
                    v-model="emailChange.email"
                    :error="$root.errors.email"
                >New Email Address
                </text-input>
              </div>
              <div class="column">
                <text-input
                    v-model="emailChange.email_confirmation"
                    :error="$root.errors.email_confirmation"
                >Confirm Email Address
                </text-input>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <submit-button
                    :working="changingEmail"
                    @submit="changeEmail"
                    class="is-success"
                    left-icon="save"
                >Change Email Address
                </submit-button>
              </div>
            </div>
          </div>

          <div class="box">
            <h2 class="box-title">Notification Preferences</h2>
            <p class="mb-1 mt has-text-cool-blue-text is-flex justify-between">
              <span>
                Select which notifications this {{ Naming.User.toLowerCase() }} should receive based on their role(s).
              </span>
              <em>Saves automatically.</em>
            </p>

            <div
                class="columns"
                v-for="(channels, group) in groupedNotificationChannels"
                :key="group">
              <div class="column">
                <h4 class="title is-5 has-text-primary">{{ Convert(group) }}</h4>
                <div class="grid gap-1">
                  <notification-channel-check
                      v-for="channel in channels"
                      :key="channel.id"
                      v-bind="{ channel }"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="box">
            <h2 class="box-title">Profile Picture</h2>
            <div class="columns">
              <div class="column">
                <img
                    @click="openImageUploader"
                    :src="user.profile.picture_url"
                    v-if="user.profile.picture_url"/>
                <action-button @click="openImageUploader" v-else>
                  Upload Picture
                </action-button>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <h2 class="box-title">Change {{ Naming.User }} Password</h2>
                <password-input
                    :label="false"
                    meter
                    placeholder="New Password"
                    :error="$root.errors.password"
                    v-model="reset.password"/>
                <password-input
                    :label="false"
                    placeholder="Confirm New Password"
                    left-icon="asterisk"
                    :error="$root.errors.password_confirmation"
                    v-model="reset.password_confirmation"/>
              </div>
            </div>
            <div class="columns">
              <div class="column has-text-right">
                <submit-button :working="working" @submit="resetPassword">
                  Change Password
                </submit-button>
              </div>
            </div>
          </div>

          <div class="box" v-if="this.isAdmin">
            <h2 class="box-title">Security Settings</h2>
            <div class="columns">
              <div class="column">
                <span><b>Account Locked</b></span>
                <p class="pb-2">If the user account has been locked due to many password attempts, unlock here</p>
                <submit-button v-if="!user.is_locked" :working="working" @submit="unlock" class="is-success" :disabled="!user.is_locked">
                  Account is active
                </submit-button>
                <submit-button v-if="user.is_locked" :working="working" @submit="unlock" class="is-success">
                  Unlock Account
                </submit-button>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <span><b>Multi-factor Authentication</b></span>
                <p class="pb-2">If the user has lost their MFA device, you can reset MFA here</p>
                <submit-button class="is-danger" :working="working" @submit="disableMFA">
                  Disable & Reset MFA
                </submit-button>
              </div>
            </div>
          </div>

          <!-- User Personas -->
          <div class="box" v-if="canModifyPersonas">
            <div class="columns">
              <div class="column">
                <h2 class="box-title">{{ Naming.User }} Persona</h2>
                <p>Assign a User Persona to this user. This will assign the associated, or modify the associated, Site
                  accessibility list.</p>
                <div v-if="hasPersonaAssigned">
                  <p class="mt-1 mb-1">Currently assigned Persona: <span class="has-text-weight-bold">{{
                      hasPersona
                    }}</span></p>
                  <div class="columns">
                    <div class="column has-text-right">
                      <submit-button :working="validatingPersonas" @submit="detachPersonaFromUser"
                                     class="is-danger mr-1">
                        Detach Persona
                      </submit-button>
                    </div>
                  </div>
                </div>
                <div v-if="!hasPersonaAssigned">
                  <server-data-selector
                      searchable
                      class="is-marginless"
                      :value="filters.persona"
                      :items="personas.data"
                      v-model="selectedPersonaId"
                      left-icon="users"
                      value-key="id"
                      label-key="name"
                      :prompt-label="`${Naming.User} Personas`"
                      :on-type="searchPersona"
                      :on-lose-focus="clearPersonaFilter">
                    <data-selector-persona-slot
                        :persona="item"
                        slot="item"
                        slot-scope="{item}"
                    />
                    <action-button
                        v-if="filters.persona"
                        slot="right"
                        @click="clearPersonaFilter()">
                      <icon icon="times"/>
                    </action-button>
                  </server-data-selector>
                  <div class="columns">
                    <div class="column has-text-right mt-2">
                      <submit-button :working="validatingPersonas" @submit="assignPersona">
                        Assign Persona
                      </submit-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box" v-if="!canModifyPersonas">
            <div class="columns">
              <div class="column">
                <h2 class="box-title">{{ Naming.User }} Persona</h2>
                <p class="mt-1 mb-1">Currently assigned Persona: <span class="has-text-weight-bold">{{
                    hasPersona
                  }}</span></p>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <h2 class="box-title">
                  {{ isAdmin ? this.Convert("Downgrade User") : this.Convert("Upgrade User") }}
                </h2>

                <p v-if="isAdmin">
                  <strong>{{ user.name }}</strong> is currently an
                  <strong>Admin</strong> {{ Naming.User.toLowerCase() }}. Click the Downgrade button below
                  to lower the access level to <strong>{{ Naming.Technician }}.</strong>
                </p>
                <p v-else>
                  <strong>{{ user.name }}</strong> is currently a
                  <strong>{{ Naming.Technician }}</strong>. Click the Upgrade button below to
                  increase the access level to <strong>Admin.</strong>
                </p>
              </div>
            </div>
            <div class="columns">
              <div class="column has-text-right">
                <submit-button
                    :disabled="!$root.hasAbility('upgrade-downgrade', 'App\\User')"
                    :working="changingRole"
                    @submit="changeRole"
                    :class="isAdmin ? 'is-danger' : 'is-primary'">
                  {{ isAdmin ? this.Convert("Downgrade User") : this.Convert("Upgrade User") }}
                </submit-button>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <h2 class="box-title">{{ Naming.User }} Status</h2>

                <div v-if="user.active">
                  <p class="mb-1">
                    This {{ Naming.User.toLowerCase() }} is currently active within your account. If you
                    would like to deactivate the {{ Naming.User.toLowerCase() }}, please use the button
                    below.
                  </p>
                  <div class="buttons is-right">
                    <action-button
                        :disabled="!$root.hasAbility('deactivate-users', 'App\\User')"
                        @click="deactivateUser"
                        class="is-danger">
                      Deactivate {{ Naming.User }}
                    </action-button>
                  </div>
                </div>

                <div v-if="!user.active">
                  <p class="mb-1">
                    This {{ Naming.User.toLowerCase() }} is currently inactive within your account.
                  </p>
                  <div class="buttons is-right">
                    <action-button @click="activateUser" class="is-primary">
                      Activate {{ Naming.User }}
                    </action-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </loader>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {uploadProfilePic} from "@/modals";
import Search from "@/utils/search";

export default {
  data: () => ({
    loading: true,
    working: false,
    uploading: false,
    changingRole: false,
    changingEmail: false,
    validatingPersonas: false,
    reset: {
      password: "",
      password_confirmation: "",
    },
    emailChange: {
      email: "",
      email_confirmation: "",
    },
    filters: {
      persona: {}
    },
    selectedPersonaId: null,
  }),

  beforeCreate() {
    this.$store.commit("user/clear");
  },

  created() {
    Promise.all([
      this.$store.dispatch("site/loadSiteList"),
      this.$store.dispatch("list/loadTimezones"),
      this.$store.dispatch("role/loadRoleList"),
      this.$store.dispatch(
          "company/loadSubscribableNotificationTypesByUser",
          this.$route.params.user
      ),
      this.$store.dispatch("userPersonas/loadPersonas", {path: 'api/users/personas'}),
      this.$store.dispatch("user/loadUser", this.$route.params.user),
    ]).finally(() => {
      this.loading = false
    });
  },

  methods: {
    ...mapMutations("user", [
      "name",
      "lastName",
      "email",
      "roles",
      "mobile",
      "toggleActive",
      "mobile",
      "dialingCode",
      "sites",
      "updateTimezone",
      "updateEmployeeNumber",
      "updateIdNumber",
      "updateCertificationNumber",
    ]),
    ...mapActions("userPersonas", ["validateCanAssignPersona", "attachPersona", "detachPersona"]),
    openImageUploader() {
      uploadProfilePic(this.user.id);
    },
    async detachPersonaFromUser() {

      this.validatingPersonas = true
      let removePersona = await this.confirmRemovalOfPersona()

      if (removePersona === 1) {

        await this.detachPersona(this.user)
        this.$router.go(0)
      }

      this.validatingPersonas = false
    },
    async confirmRemovalOfPersona() {
      return this.$confirm({
        title: "Warning: Detach Persona",
        message: `Detaching this ${this.Naming.User} Persona will detach the Persona from the ${this.Naming.User} and will <b>not</b> affect their Site Access.`,
        confirmText: "Detach",
        cancelText: "Cancel",
        confirmClass: 'is-danger',
        cancelClass: 'is-info',
      })
          .catch(() => {
            this.$whoops()
          })
    },
    async assignPersona() {
      this.validatingPersonas = true
      const payload = {
        persona_id: this.selectedPersonaId,
        user_id: this.user.id
      }
      await this.validateCanAssignPersona(payload)
          .then()
          .catch((error) => {
                this.$toast.error("Oops, something went wrong please try again")
              }
          )
      if (this.canAssignPersona.can_transfer === true) {
        this.attachPersona(payload)
            .then(() => {
              this.$router.go(0)
            })
            .catch((error) => {
              this.$toast.error(`An error occurred: ${error}`)
            })
            .finally(() => {
              this.validatingPersonas = false
            })
      } else {
        this.$toast.warning(`Cannot assign this Persona as ${this.Naming.User} has ${this.canAssignPersona.open_jobs} open ${this.Naming.Jobs}.`)
      }
      this.validatingPersonas = false
    },
    searchPersona(text) {
      Search.debouncedSearchByUri(text, 'userPersonas/searchPersonas', this.$store)
    },
    clearPersonaFilter() {
      this.filters.persona = {}
      this.selectedPersonaId = null
    },
    save() {
      this.working = true;
      this.$store
          .dispatch("user/save")
          .then((response) => {
            this.$toast.success("Saved")
            this.working = false
          })
          .catch((error) => {
            this.$toast.error("Oops, something went wrong please try again")
            this.working = false
          })
    },
    async unlock() {
      this.$store
          .dispatch("user/unlockUser", {userId: this.user.id})
          .then((response) => {
            this.$toast.success("Unlocked")
            this.working = false
          })
          .catch((error) => {
            this.$toast.error("Oops, something went wrong please try again")
            this.working = false
          });
    },
    async disableMFA() {
      this.$store
          .dispatch("user/disableMFA", {userId: this.user.id})
          .then((response) => {
            this.$toast.success("MFA Disabled")
            this.working = false
          })
          .catch((error) => {
            this.$toast.error("Oops, something went wrong please try again")
            this.working = false
          });
    },
    async resetPassword() {
      if (
          await this.$confirm({
            title: this.Convert("Change User Password"),
            message: this.Convert("Are you sure you want to change this users password?"),
          })
      ) {
        this.working = true
        this.$store
            .dispatch("user/resetPassword", this.reset)
            .then((response) => {
              this.$toast.success("Saved")
              this.working = false
              this.reset = {
                password: "",
                password_confirmation: ""
              }
            })
            .catch((error) => {
              this.$toast.error("Oops, something went wrong please try again")
              this.working = false
            });
      }
    },
    async deactivateUser() {
      if (
          await this.$confirm({
            title: this.Convert("Deactivate User"),
            message: this.Convert("Are you sure you want to deactivate this user?")
          })
      ) {
        this.working = true
        this.$store
            .dispatch("user/deactivate", this.user.id)
            .then((response) => {
              this.$toast.success("Deactivated");
              this.$router.push({
                name: "user-index"
              });
              this.working = false
            })
            .catch((error) => {
              this.$toast.error("Oops, something went wrong please try again")
              this.working = false
            });
      }
    },
    async activateUser() {
      if (
          await this.$confirm({
            title: this.Convert("Activate User"),
            message: this.Convert("Are you sure you want to activate this user?")
          })
      ) {
        this.working = true
        this.$store
            .dispatch("user/activate", this.user.id)
            .then((response) => {
              this.$toast.success("Activated");
              this.$router.push({
                name: "user-index"
              });
              this.working = false
            })
            .catch((error) => {
              this.$toast.error("Oops, something went wrong please try again")
              this.working = false
            })
      }
    },
    async changeEmail() {
      if (
          await this.$confirm({
            title: this.Convert("Change User Email Address"),
            message: this.Convert("Are you sure you want to change this users email address?")
          })
      ) {
        this.changingEmail = true;
        this.$store
            .dispatch("user/changeEmail", this.emailChange)
            .then(() => {
              this.$toast.success("Email address updated")
              this.$store.commit("user/email", this.emailChange.email)

              this.emailChange = {
                email: "",
                email_confirmation: ""
              };
              this.changingEmail = false
            })
            .catch((error) => {
              this.changingEmail = false
              this.$whoops()
            });
      }
    },
    async changeRole() {
      if (
          await this.$confirm({
            title: this.isAdmin
                ? this.Convert("Downgrade user to Technician")
                : this.Convert("Upgrade user to Admin"),
            message: this.isAdmin
                ? this.Convert("Are you sure you want to downgrade this user to a technician role?")
                : this.Convert("Are you sure you want to upgrade this user to an Admin role?")
          })
      ) {
        var action = this.isAdmin ? "downgradeToTechnician" : "upgradeToAdmin"
        this.changingRole = true
        this.$store
            .dispatch(`user/${action}`)
            .then(() => {
              this.changingRole = false;
              this.$toast.success("Role successfully updated");
              this.$store.dispatch("user/loadUser", this.$route.params.user);
              this.$store.dispatch(
                  "company/loadSubscribableNotificationTypesByUser",
                  this.$route.params.user
              );
            })
            .catch((error) => {
              this.changingRole = false
              this.$whoops()
            });
      }
    },
  },

  computed: {
    ...mapGetters("user", ["user", "isAdmin"]),
    ...mapGetters("list", ["timezones"]),
    ...mapGetters("company", ["groupedNotificationChannels"]),
    ...mapGetters("userPersonas", ["personas", "canAssignPersona", "persona"]),
    hasPersona() {
      const text = this.user?.persona?.name

      return text?.length > 0 ? text : 'None'
    },
    hasPersonaAssigned() {
      return this.user?.persona?.name?.length > 0
    },
    canModifyPersonas() {
      return this.$root.hasAbility('edit-users', 'App\\User') && this.$root.hasAbility('manage-personas', 'App\\User')
    }
  }
}
</script>
