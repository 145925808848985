<template>
  <div style="margin-bottom: 2px;" class="grid has-11-columns pt-075 pb-075 rounded-sml is-uppercase tracking-wide has-text-grey has-background-white" :class="{'has-background-white-bis' : creatingAssetType}">
    <div class="is-1-column"></div>
    <div class="is-2-columns">Field Label</div>
    <div class="is-2-columns">Placeholder</div>
    <div class="is-2-columns">Default</div>
    <div class="is-1-columns">Required</div>
    <div class="is-2-columns"></div>
    <div class="is-1-column"></div>
  </div>
</template>
<script>
  export default {
    props: {
      creatingAssetType: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>