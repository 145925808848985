<template>
  <tr v-if="!editing">
    <td><p @click="editing = true" class="hover-bold">{{ status.name }}</p></td>
    <td>{{ status.description || '-' }}</td>
    <td>{{ status.options.length }}</td>
    <td class="is-flex justify-end">
      <action-button @click="editing = true"
                     class="is-rounded is-borderless hover-background-grey">
        <icon icon="chevron-down" class="has-text-grey"/>
      </action-button>
    </td>
  </tr>

  <tr v-else class="has-background-white-bis">
    <td>
      <text-input required
                  v-model="status.name"
                  placeholder="Enter status name">
        Status Name
      </text-input>
    </td>
    <td>
      <text-input v-model="status.description"
                  placeholder="Enter status description">
        Status Description
      </text-input>
    </td>
    <!--    Options-->
    <td>
      <field-label class="has-text-weight-bold" required>Options</field-label>
      <draggable
          handle="#handle"
          :animation="200"
          v-model="status.options"
      >
        <transition-group>
          <dynamic-option v-for="(option, index) in status.options"
                          :option="option"
                          :index="index"
                          :key="index"
                          @addOption="addOption"
                          @removeOption="removeOption"
          />
        </transition-group>
      </draggable>
      <div class="is-flex align-items-center justify-center">
        <action-button @click="addOption"
                       data-tooltip="Add Option"
                       class="is-rounded has-tooltip-bottom hover-success">
          <icon icon="plus" class="has-text-grey"/>
        </action-button>
      </div>
    </td>
    <!--    Buttons-->
    <td>
      <div class="is-flex justify-end align-items-start">
        <icon v-show="canSave" @click="closeEditing" icon="chevron-up" :data-tooltip="closeMessage" class="has-text-grey has-tooltip-bottom mr-1"/>
      </div>
      <div class="is-flex justify-center align-items-center" style="margin-top:8px">
        <action-button class="is-rounded mr-1 is-success has-tooltip-bottom"
                       :data-tooltip="closeMessage"
                       :disabled="!canSave"
                       @click="closeEditing">
          <icon icon="check"/>
        </action-button>
        <action-button :working="working"
                       class="is-rounded is-danger"
                       @click="deleteStatus">
          <icon icon="trash" />
        </action-button>
      </div>
    </td>
  </tr>
</template>

<script>

import DynamicOption from "@/views/asset-type/partials/DynamicOption.vue";
import DynamicStatusOption from "@/models/DynamicStatusOption";

export default {
  components: {DynamicOption},
  props: {
    status: {
      type: Object,
      default: () => ({
        id: null,
        name: '',
        description: '',
        asset_type_id: null,
        options: [
          {
            value: '',
            dynamic_status_id: null
          }
        ],
        editable: true
      }),
    },
  },
  data: () => ({
    working: false,
    editing: false,
  }),

  methods: {
    closeEditing(){
      if (this.optionValuesAreUnique === false) return this.$toast.warning("All 'Options' values are not unique. Please use unique values for each option")
      this.editing = false
    },
    deleteStatus() {
      this.$store.commit('assetType/removeStatus', this.index)
    },
    addOption() {
      this.status.options.push(new DynamicStatusOption)
    },
    removeOption(index, option) {
      if (this.status.options.length > 1) {
        return this.status.options.splice(index, 1)
      }
      option.value = ''
    },
  },

  computed: {
    DynamicStatusOption() {
      return DynamicStatusOption
    },
    canSave() {
      return this.status.name.length > 0 &&
          this.status.options?.length > 0 &&
          this.status.options.every(option => option.value !== '')
    },
    closeMessage(){
      if(this.canSave) return "Collapse Editing"
      return "Please ensure all required fields are completed"
    },
    optionValuesAreUnique(){
      let values = this.status.options.map(option => option.value)
      return new Set(values).size === values.length
    }


  },
};
</script>
