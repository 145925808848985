import { render, staticRenderFns } from "./SiteAccess.vue?vue&type=template&id=574ba2b6&"
import script from "./SiteAccess.vue?vue&type=script&lang=js&"
export * from "./SiteAccess.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports