<template>
  <loader v-bind="{ loading }">
    <div class="columns">
      <div class="column">
        <form>
          <div class="box">
            <div class="is-size-5 has-text-weight-light mb-1">
              {{ Naming.Site }} Details
            </div>
            <div class="columns">
              <div class="column">
                <text-input :value="site.name" @input="name">Name</text-input>
              </div>
              <div class="column">
                <text-input :value="site.legal_name" @input="legalName">Legal Name</text-input>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <text-input :value="site.registration_number" @input="registrationNumber">Registration Number
                </text-input>
              </div>
              <div class="column">
                <text-input :value="site.vat_number" @input="vatNumber">VAT Number</text-input>
              </div>
            </div>
            <div class="columns">
              <div class="column is-6">
                <phone-num
                  :number="site.phone_number" 
                  :code="site.dialing_code" 
                  @code="dialingCode"
                  @number="phoneNumber"/>
              </div>
            </div>
            <div class="columns">
              <div class="column is-6" v-if="isAdmin">
                <data-selector
                    :items="dropdownClientList"
                    searchable
                    :value="site.client_id"
                    @input="client">
                  {{ Naming.Client }}
                </data-selector>
              </div>
              <div class="column is-6">
                <data-selector
                    :value="site.site_manager_user_id"
                    searchable
                    left-icon="user-tie"
                    :prompt-label="`Select a ${Naming.Site.toLowerCase()} manager`"
                    classes="has-text-grey"
                    :items="siteAdminsList"
                    label-key="full_name"
                    value-key="id"
                    @input="siteManager">
                  Select {{ Naming.Site }} Manager
                  <action-button
                      v-if="site.site_manager_user_id > 0"
                      slot="right"
                      @click="clearSiteManager">
                    <icon icon="times"/>
                  </action-button>
                </data-selector>
              </div>
            </div>
            <physical-address :search="true" :addr="site.address" @input="setAddress" :title="`${Naming.Site} Address`"/>
            <div v-if="hasCustomFields && $root.hasAbility('update-site-custom-field-values', 'App\\Models\\CustomField')">
              <div class="is-size-5 has-text-weight-light mtb-1">
                {{ Naming.CustomFields }}
              </div>
              <div class="columns is-multiline">
                <custom-field-value-input
                    v-for="customField in site.custom_fields"
                    :key="customField.id"
                    :customField="customField"
                    @valueEntered="assignValue"/>
              </div>
              <div class="is-flex justify-end">
                <router-link
                    :to="{ name: 'site-custom-fields',
                           params: 'this.$route.params'}"
                    class="button is-cool-blue-darker is-small is-rounded">
                  <icon icon="angle-double-right"></icon>
                  <span>{{ Naming.CustomFields }}</span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="buttons is-spaced">
            <submit-button class="is-success is-rounded" :disabled="uploading" :working="working" @submit="save"
                           left-icon="check">Save Info
            </submit-button>
            <action-button
                :disabled="! $root.hasAbility('delete-sites', 'Billow\\Models\\Site') || working || uploading"
                @click="deleteSite" class="is-danger is-rounded is-inverted" left-icon="trash">Delete {{ Naming.Site }}
            </action-button>
          </div>
        </form>
      </div>
      <div class="column is-3" v-if="isAdmin">
        <div class="box">
          <h2 class="box-title">{{ Naming.Site }} Logo</h2>
          <img @click="openImageUploader" :src="site.logo_url" v-if="site.logo_url">
          <p v-else>A logo hasn’t been uploaded for this {{ Naming.Site.toLowerCase() }} yet.</p>
          <div class="is-flex justify-between mt-1">
            <action-button
                @click="openImageUploader"
                left-icon="upload"
                class="is-primary is-small is-rounded mt-1">
              Upload Logo
            </action-button>
            <action-button
                v-if="site.logo_url"
                @click="reset"
                left-icon="redo-alt"
                class="is-danger is-small is-rounded mt-1"
                :working="uploading">
              Reset Logo
            </action-button>
          </div>
        </div>
        <div class="box">
          <h2 class="box-title">{{ Naming.CustomFields }}</h2>
          <p>
            This {{ Naming.Site.toLowerCase() }} has
            <span v-if="site.custom_fields_count">{{ site.custom_fields_count }}</span>
            <span v-else>no </span>
            {{ Naming.CustomFields.toLowerCase() }}.
          </p>
          <router-link
              v-if="$root.hasAbility('update-site-custom-field-values', 'App\\Models\\CustomField')"
              :to="{ name: 'site-custom-fields',
             params: 'this.$route.params'}"
              class="is button is-info is-small is-rounded mt-1">
            <icon icon="clipboard-list"></icon>
            <span>{{ Naming.CustomFields }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </loader>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import {uploadSiteLogo} from '@/modals'

export default {

  data: () => ({
    working: false,
    uploading: false,
    loading: true,
  }),

  computed: {
    ...mapGetters('site', [
      'site'
    ]),
    ...mapGetters('client', [
      'dropdownClientList'
    ]),
    ...mapGetters('site', [
      'siteAdminsList'
    ]),
    hasCustomFields() {
      return this.site?.custom_fields?.length
    }
  },

  beforeCreate() {
    Promise.all([
      this.$store.dispatch('site/loadSiteForEditing', this.$route.params.site),
      this.$store.dispatch('client/loadDropdownClientList'),
      this.$store.dispatch('site/fetchSiteAdmins', this.$route.params.site)
    ]).then(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapMutations('site', [
      'name',
      'legalName',
      'registrationNumber',
      'vatNumber',
      'failureThreshold',
      'client',
      'setAddress',
      'users',
      'siteManager',
      'phoneNumber',
      'dialingCode'
    ]),
    openImageUploader() {
      uploadSiteLogo()
    },
    reset() {
      this.uploading = true
      this.$store.dispatch('site/resetSiteLogo').then(() => {
        this.uploading = false
        this.$toast.success(this.Convert('Success', 'The Site logo has been reset.'))
      })
    },
    save() {
      this.working = true
      this.$store.dispatch('site/save').then(() => {
        this.working = false
        this.$toast.success(this.Convert(this.$lang.site.saved))
      }).catch(error => {
        this.$whoops()
        this.working = false
      })
    },
    async deleteSite() {
      const { title, message, confirmText } = this.$lang.site.confirmDelete;
      const convertedTitle = await this.Convert(title);
      const convertedMessage = await this.Convert(message);
      const convertedConfirmText = await this.Convert(confirmText);

      if (await this.$confirm({
        title: convertedTitle,
        message: convertedMessage,
        confirmText: convertedConfirmText,
        confirmClass: 'is-danger'
      })) {
        this.$store.dispatch('site/deleteSite').then(() => {
          this.$toast.success(this.Convert(this.$lang.site.deleted));
          this.$router.push({ name: 'site-admin-index' });
        }).catch(error => {
          console.error(error);
          this.$whoops();
        });
      }
    },
    assignValue(parameters) {
      parameters.customField.value = parameters.value
      this.$store.dispatch('customField/updateCustomField', parameters.customField)
    },
    clearSiteManager() {
      this.$store.commit('site/clearManager')
      this.site.site_manager_user_id = ''
    }
  }
}
</script>