<template>
  <div class="grid has-11-columns has-background-white rounded-sml custom-field-item pt-075 pb-075" style="margin-bottom: 2px" >
    <div class="is-1-column is-flex is-flex-column align-items-start pl-1 justify-center hover-move" id="handle">
      <icon class="has-text-grey is-size-6" icon="grip-vertical"/>
    </div>
    <div class="is-2-columns rounded-sml is-flex align-items-center has-text-primary">
      <tag-icon style="margin-right: 0.25rem" :color="labelTagColour" />
      <input
          type="text"
          v-model="field.label"
          :disabled="locked"
          class="custom-field-input"
          @input="updateFieldLabel"/>
    </div>
    <div class="is-2-columns rounded-sml is-flex align-items-center">
      <maximize-icon style="margin-right: 0.25rem" :color="placeholderTagColour" />
      <input
          type="text"
          v-model="field.placeholder"
          :disabled="locked"
          class="custom-field-input"
          @input="updateFieldPlaceholder"
      />
    </div>
    <div class="is-2-columns rounded-sml is-flex align-items-center">
      <default-grid-icon style="margin-right: 0.25rem" :color="defaultTagColour" :size="18" />
      <input
          type="text"
          class="custom-field-input"
          v-model="field.default"
          @input="updateFieldDefault"/>
    </div>
    <div class="is-1-columns is-flex align-items-center justify-start rounded-sml">
      <switch-input
          classes="is-primary is-rounded is-small"
          :value="field.is_required"
          :disabled="locked"
          @input="toggleRequiredField">
      </switch-input>
    </div>
    <div class="is-2-columns rounded-sml is-flex align-items-center justify-center" v-if="!locked">
      <action-button
          style="border-radius: 5px"
          :left-icon="this.predefinedValueCount > 0 ? 'list' : 'plus-circle'"
          @click="showPredefinedValuesEditor"
          :disabled="locked"
          :data-tooltip="predefinedTooltip"
          :class="predefinedListColour"
          class="is-small rounded-sml has-text-weight-semibold has-tooltip-multiline">
        Predefined Values
      </action-button>
    </div>
    <div v-else class="is-2-columns rounded-sml">
      <p class="has-text-weight-bold">Predefined Values</p>
      <p class="ml-1" v-for="option in field.options">
        - {{ option.value }}
      </p>
    </div>
    <div class="is-1-column is-flex align-items-center justify-center rounded-sml">
      <icon class="hover-highlight-danger has-text-grey is-size-5" icon="times-circle" @click="deleteField"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {predefinedValuesEditor} from "@/modals";
import TagIcon from "@/components/ui/icons/TagIcon.vue";
import MaximizeIcon from "@/components/ui/icons/MaximizeIcon.vue";
import DefaultGridIcon from "@/components/ui/icons/DefaultGridIcon.vue";

export default {
  components: {DefaultGridIcon, MaximizeIcon, TagIcon},
  props: {
    field: {
      type: Object,
      default: () => ({
        field_id: '',
        label: '',
        is_required: false,
        options: [],
        default: false,
      }),
    },
    index: {
      type: Number,
    },
    locked: {
      type: Boolean,
    }
  },

  methods: {
    updateFieldLabel(event) {
      this.$store.commit('assetType/updateFieldLabel', {
        fieldId: this.field.field_id,
        value: event.target.value
      })
    },
    updateFieldPlaceholder(event) {
      this.$store.commit('assetType/updateFieldPlaceholder', {
        fieldId: this.field.field_id,
        value: event.target.value
      })
    },
    updateFieldDefault(event) {
      this.$store.commit('assetType/updateFieldDefault', {
        fieldId: this.field.field_id,
        value: event.target.value
      })
    },
    toggleRequiredField() {
      this.$store.commit('assetType/toggleRequiredField', this.field.field_id)
    },
    async deleteField() {
      if (await this.$confirm({
        title: this.Convert('Delete Field'),
        message: this.Convert('Are you sure you want to remove this custom field? All assets with values assigned to this field will lose their saved value.')
      })) {
        this.$store.commit('assetType/deleteField', this.field)
      }
    },
    showPredefinedValuesEditor() {
      this.$store.commit('assetType/setFieldId', this.field.field_id)
      predefinedValuesEditor().then()
    },
  },

  computed: {
    ...mapGetters('assetType', [
      'assetType'
    ]),
    predefinedValueCount() {
      return this.field?.options?.length ?? 0
    },
    labelTagColour(){
      return this.field.label?.length > 0 ? 'has-text-primary' : 'has-text-grey'
    },
    placeholderTagColour(){
      return this.field.placeholder?.length > 0 ? 'has-text-primary' : 'has-text-grey'
    },
    defaultTagColour(){
      return this.field.default?.length > 0 ? 'has-text-primary' : 'has-text-grey'
    },
    predefinedListColour(){
      return this.predefinedValueCount > 0 ? 'is-primary' : 'has-text-grey'
    },
    predefinedTooltip() {
      if (!this.predefinedValueCount) {
        return null;
      }
      const values = this.field?.options?.map(option => option.value).join(', ')
      return `${this.predefinedValueCount} Values: ${values}`
    },
  },

  watch: {
    field: {
      handler() {
        this.$emit('changed')
      },
      deep: true,
    },
    index: {
      handler() {
        this.$emit('changed')
      },
    },
  },
}
</script>
