<template>
  <masked-text-input
    :required="required"
    :classes="size"
    :error="$root.errors['profile.dialing_code'] || $root.errors['profile.mobile'] || $root.errors['mobile'] || $root.errors['dialing_code']"
    :value="phone.number"
    :mask="''"
    @input="updateNumber">
    <data-selector
      :classes="size"
      no-revert
      searchable
      :value="phone.code"
      @input="updateCode"
      prompt-label="code"
      :items="dialingCodes"
      slot="left"/>
    Mobile Number
  </masked-text-input>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  props: {
    number: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    size: String,
    required: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    phone: {
      code: '',
      number: ''
    }
  }),

  created() {
    this.phone.number = this.number
    this.phone.code = this.code
  },

  methods: {
    updateCode(value) {
      this.phone.code = value
      this.$emit('code', value)
    },
    updateNumber(value) {
      this.phone.number = value
      this.$emit('number', value)
    }
  },

  computed: {
    ...mapGetters('list', [
      'dialingCodes'
    ])
  }

}
</script>
