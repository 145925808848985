<template>
  <div>
    <div style="margin-bottom: 2px;" :class="{'has-background-white-bis' : creatingAssetType}" class="grid has-11-columns pt-075 pb-075 rounded-sml is-uppercase tracking-wide has-text-grey has-background-white" >
      <div class="is-1-column"></div>
      <div class="is-2-columns">Field Label</div>
      <div class="is-2-columns">Placeholder</div>
      <div class="is-2-columns">Default</div>
      <div class="is-1-columns">Required</div>
      <div class="is-2-columns"></div>
      <div class="is-1-column is-flex justify-end">
        <icon v-if="!creatingAssetType" @click="closeNewField" class="has-text-grey is-size-5 mr-1 hover-highlight-danger" icon="times-circle" />
      </div>
    </div>
    <div class="grid has-11-columns has-background-white rounded-sml p-05">
      <div class="is-1-column is-flex is-flex-column align-items-start pl-1 justify-center">
        <icon @click="addCustomField" class="has-text-primary is-size-4" icon="plus-circle" />
      </div>
      <div class="is-2-columns rounded-sml pr-2">
        <input
            type="text"
            required
            v-model="field.label"
            class="custom-field-input new-field"
        />
      </div>
      <div class="is-2-columns rounded-sml pr-2">
        <input
            type="text"
            v-model="field.placeholder"
            class="custom-field-input new-field"
        />
      </div>
      <div class="is-2-columns rounded-sml pr-2">
        <input
            type="text"
            class="custom-field-input new-field"
            v-model="field.default"
        />
      </div>
      <div class="is-1-column is-flex align-items-center justify-start rounded-sml">
        <switch-input
            class="pl-025"
            classes="is-primary is-small is-rounded"
            @input="field.is_required = !field.is_required"
            :value="field.is_required">
        </switch-input>
      </div>
      <div class="is-3-columns is-flex align-items-center justify-end rounded-sml">
        <action-button
            :disabled="!field.label.length"
            @click="addCustomField"
            left-icon="plus-circle"
            class="is-success is-inverted rounded-sml has-text-weight-semibold mr-1">
          Add New
        </action-button>
      </div>
    </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import CustomFieldsTableHeader from "@/views/asset-type/partials/CustomFieldsTableHeader.vue";

export default {
  components: {CustomFieldsTableHeader},

  props: {
    creatingAssetType: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    field: {
      field_id: '',
      label: '',
      is_required: false,
      default: '',
      options: [],
    },
  }),

  created() {
    this.field.field_id = uuidv4()
  },

  methods: {
    addCustomField() {
      if (!this.field.label.length) {
        this.$toast.error('Please provide a field label.');
        return;
      }

      this.$store.commit('assetType/addCustomField', this.field);
      this.$emit('changed')

      if(this.field.default.length) {
        this.$store.commit('assetType/addDefaultCustomFieldOption', this.field)
      }

      this.field = {
        field_id: uuidv4(),
        label: '',
        is_required: false,
        default: '',
        options: [],
      }
    },
    closeNewField(){
      this.field = {
        field_id: uuidv4(),
        label: '',
        is_required: false,
        default: '',
        options: [],
      }
      this.$emit('close')
    }
  },

  computed: {
    ...mapGetters('assetType', [
      'assetType'
    ])
  },
};
</script>
