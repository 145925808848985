<template>
  <div class="field">
    <field-label v-if="label" v-bind="{ required, requiredClass, requiredText, hint, hintIcon, hintClass, description }">
      <slot/>
    </field-label>
    <p :class="['field-description', descriptionClass]" v-if="description">{{ description }}</p>
    <input
      :class="[classes]"
      :id="fieldIdentifier"
      :checked="value"
      :disabled="disabled"
      class="is-checkradio"
      type="checkbox">
    <label :for="fieldIdentifier" v-html="innerLabel" @click.prevent="updateInput"/>
    <field-error v-if="hasError" v-bind="{ error, errorClass, errorSymbol }"/>
  </div>
</template>

<script>
import * as props from '@/mixins/props'
import * as computed from '@/mixins/computed'

export default {

  mixins: [
    props.classes,
    props.describable,
    props.disabled,
    props.error,
    props.fieldId,
    props.hintable,
    props.innerLabel,
    props.label,
    props.required,

    computed.identifiesFields,
    computed.hasError,
  ],

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    }
  },

  methods: {
    updateInput() {
      !this.disabled && this.$emit('input', !this.value)
    }
  }
}
</script>

<style>
  /* Margin override */
  .is-checkradio[type="checkbox"] + label {
    margin-left: 0 !important;
  }
</style>
