<template>
  <div class="box mtb-1 columns pt-025">
    <div class="column p-1 pt-0">
      <div class="pb-1">
        <h4 class="is-size-4">Recently Inspected {{ Naming.Assets }}</h4>
        <p v-if="reachedAssetLimit" class="has-text-grey">Limited to five hundred {{ Naming.Assets.toLowerCase() }}</p>
      </div>
      <div class="inspection-map-container">
        <!-- Loading Spinner -->
        <loader v-if="isLoading"></loader>
        <!-- Error Message -->
        <div v-else-if="error" class="error-container">
          <p>{{ error }}</p>
          <button @click="fetchInspectionLocations">Retry</button>
        </div>
        <!-- Map Display -->
        <template v-else-if="assets.length > 0">
          <gmap-map
              :center="mapCenter"
              :zoom="10"
              style="width: 100%; height: 500px"
          >
            <gmap-marker
                v-for="(location, index) in recentInspectionLocations"
                :key="index"
                :position="{ lat: location.location.latitude, lng: location.location.longitude }"
                :clickable="true"
                @click="showLocationDetails(location)"
            ></gmap-marker>
          </gmap-map>
        </template>
        <!-- No Locations Message -->
        <div v-else class="no-locations-container">
          <p>No recent inspection locations found.</p>
          <button @click="fetchInspectionLocations">Refresh</button>
        </div>
        <!-- Location Details Modal -->
        <div v-if="selectedAssetLocation" class="location-details-modal">
          <div class="modal-content">
            <p><strong>Internal id: </strong>
              <router-link
                  class="has-text-weight-bold has-text-link"
                  :to="{
                        name: 'asset-overview',
                        params: {
                          asset: selectedAssetLocation.id,
                        }
                      }"
              >{{ selectedAssetLocation.code }}
              </router-link>
            </p>
            <p v-if="selectedAssetLocation.last_inspection"><strong>Completed: </strong> {{ selectedAssetLocation.last_inspection | date('L LTS') }}</p>
            <p><strong>Status: </strong><current-status :latest-status="assetStatus" /> </p>
            <button @click="selectedAssetLocation = null">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {common as commonBackend} from "@/api";
import {mapState} from "vuex";

export default {
  name: 'InspectionLocationsMap',
  data() {
    return {
      mapCenter: null,
      selectedAssetLocation: null,
      recentInspectionLocations: [],
      assets: [],
      isLoading: true,
      error: null
    }
  },
  mounted() {
    this.fetchInspectionLocations();
  },

  computed: {
    ...mapState('company', ['company']),
    companyLocation(){
      return {
        lat: Number(this.company?.address?.lat) ?? 0,
        lng: Number(this.company?.address?.lng) ?? 0
      }
    },
    reachedAssetLimit() {
      return this.assets.length >= 500
    },
    assetStatus() {
      return this.selectedAssetLocation.is_defective? 'defective' : 'ok'
    }
  },
  methods: {
    fetchInspectionLocations() {
      this.isLoading = true
      this.error = null
      this.recentInspectionLocations = []
      commonBackend.loadPath({path: `api${this.$route.fullPath}recently-inspected-assets`}, ({data}) => {
        this.assets = data ?? null
        if (this.assets.length > 0) {
          this.recentInspectionLocations = this.assets.filter(asset => asset.location)
          const first = this.recentInspectionLocations[0]
          this.mapCenter = first ?
              {lat: first.location.latitude, lng: first.location.longitude} :
              this.companyLocation
          this.isLoading = false
        }
      }, error => {
        this.error = error.message || 'Failed to load inspection locations'
        this.isLoading = false;
      })
    },
    showLocationDetails(location) {
      this.selectedAssetLocation = location;
    }
  }
}
</script>

<style scoped>
.inspection-map-container {
  margin: 0 auto;
  text-align: center;
}

.error-container, .no-locations-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.location-details-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
}

button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #2980b9;
}
</style>