<template>
  <loader v-bind="{ loading }">
  <page icon="building" :title="`#${site.id} ${site.name}`">
    <div slot="tools">
      <div class="tabs is-toggle">
        <ul>
          <router-link exact tag="li" :to="{ name: 'site-overview', params: { site: $route.params.site }}">
            <a>{{ Naming.Overview }}</a>
          </router-link>
          <router-link exact tag="li" :to="{ name: 'site-zones', params: { site: $route.params.site }}"
                       v-if="$root.hasAbility('manage-zones', 'Billow\\Models\\Site')">
            <a>{{ Naming.Zones }}</a>
          </router-link>
          <router-link exact tag="li" :to="{ name: 'site-assets', params: { site: $route.params.site }}"
                       v-if="$root.hasAbility('manage-assets', 'Billow\\Models\\Site')">
            <a>{{ Naming.Assets }}</a>
          </router-link>
          <router-link exact tag="li" :to="{ name: 'site-jobs', params: { site: $route.params.site }}">
            <a>{{ Naming.Jobs }}</a>
          </router-link>
          <router-link exact tag="li" :to="{ name: 'site-inspections', params: { site: $route.params.site }}">
            <a>{{ Naming.Inspections }}</a>
          </router-link>
          <router-link exact tag="li" :to="{ name: 'site-documents', params: { site: $route.params.site }}">
            <a>Documents</a>
          </router-link>
          <router-link v-if="$root.hasAbility('update-site-custom-field-values', 'App\\Models\\CustomField')" exact tag="li" :to="{ name: 'site-custom-fields', params: { site: $route.params.site }}">
            <a>{{ Naming.CustomFields }}</a>
          </router-link>
          <router-link v-if="company.allow_fire_register" exact tag="li" :to="{ name: 'site-asset-register', params: { site: $route.params.site }}">
            <a>{{ Naming.Asset }} Register</a>
          </router-link>

          <!-- Custom code for fidelity -->
          <a @click="download" v-if="company.id === 125">Fidelity {{ Naming.Asset }} Register</a>

          <router-link v-if="isAdmin && $root.hasAbility('manage-user-access', 'Billow\\Models\\Site')" tag="li"
                       :to="{ name: 'site-user-access', params: { site: $route.params.site }}">
            <a>{{ Naming.Users }}</a>
          </router-link>
          <router-link v-if="isAdmin && $root.hasAbility('edit-site', 'Billow\\Models\\Site')" exact tag="li"
                       :to="{ name: 'site-edit', params: { site: $route.params.site }}">
            <a>Edit</a>
          </router-link>

          <router-link exact tag="li" :to="{ name: 'site-tools', params: { site: $route.params.site }}">
            <a>Tools</a>
          </router-link>
        </ul>
      </div>
    </div>
    <router-view/>
  </page>
  </loader>
</template>

<script>
import { mapGetters } from 'vuex'
import download from 'downloadjs'
import { common as backend } from '@/api'

export default {

  data: () => ({
    loading: true,
    downloading: false,
  }),

  async created() {
    await this.loadSite()
  },

  computed: {
    ...mapGetters('site', [
      'site',
    ]),
    ...mapGetters('auth', [
      'isAdmin',
    ]),
    ...mapGetters('company', [
      'company',
    ]),
    ...mapGetters('auth', [
      'user',
    ]),
  },

  methods: {
    async loadSite() {
      this.loading = true
      await this.$store.dispatch("site/loadSiteDataForOverview", this.$route.params.site).then(() => {
        this.loading = false
      })
    },
    download() {
      this.downloading = true
      this.$toast.info('Download Started')

      backend.downloadFile(`/api/sites/${this.$route.params.site}/download-fire-register`, ({ data }) => {
        download(data, `${this.$route.params.site}.pdf`, 'application/pdf')
        this.downloading = false
      }, error => {
        this.$whoops();
        this.downloading = false
      })
    },
  },
}
</script>
