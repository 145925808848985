import User from './User'
import Address from './Address'
import Client from './Client'

export default class Site {

  constructor() {
    this.name = ''
    this.user = new User
    this.address = new Address
    this.client = new Client
    this.client_legal_name = ''
    this.client_id = ''
    this.zones = []
    this.assets = []
    this.users = []
    this.failure_threshold = 50
    this.custom_fields = []
    this.site_manager_user_id = ''
    this.phone_number = ''
    this.dialing_code = ''
  }

  get payload() {
    return {
      ...this,
      users: this.users.map(user => user.id)
    }
  }

}
