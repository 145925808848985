import Client from '@/models/Client'
import { client as backend } from '@/api'

let client = {

  namespaced: true,

  state: {
    clients: {
      data: []
    },
    clientList: [],
    dropdownClientList: [],
    sites: [],
    client: new Client,
    filters: {
      search: ''
    },
    overview: {
      job_counts: {
        total: 0,
        open: 0,
        completed: 0,
      },
      inspection_counts: {
        overdue: 0
      },
      asset_counts: {
        total: 0,
        defective: 0,
        ok: 0
      },
      site_counts: {
        with_risk: 0,
        total: 0
      }
    },
    overviewJobStats: {},
    overviewLatestJobs: []
  },

  mutations: {
    setClients: (state, payload) => state.clients = payload,
    setClientList: (state, payload) => state.clientList = payload,
    setDropdownClientList: (state, payload) => state.dropdownClientList = payload,
    setClient: (state, payload) => state.client = payload,
    setAddress: (state, payload) => state.client.address = payload,
    legalName: (state, payload) => state.client.legal_name = payload,
    tradingName: (state, payload) => state.client.trading_name = payload,
    registrationNumber: (state, payload) => state.client.registration_number = payload,
    vatNumber: (state, payload) => state.client.vat_number = payload,
    setOverview: (state, payload) => state.overview = payload,
    setOverviewLatestJobs: (state, payload) => state.overviewLatestJobs = payload,
    setOverviewJobStats: (state, payload) => state.overviewJobStats = payload,
    updateLogoUrl: (state, payload) => {
      state.client.logo = payload
      state.client.logo_url = payload
    },
    filterBy: (state, payload) => state.filters[payload.column] = payload.value,
    clearClient: (state) => state.client = new Client,
    clear: (state) => {
      state.clients = {
        data: []
      }
      state.clientList = []
      state.client = new Client
      state.filters = {
        search: ''
      }
    }
  },

  actions: {
    loadClients: ({ commit }, path) => new Promise((resolve, reject) => {
      backend.loadClients(path, ({ data }) => {
        commit('setClients', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadClientList: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadClientList(({ data }) => {
        commit('setClientList', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadDropdownClientList: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadDropdownClientList(({ data }) => {
        commit('setDropdownClientList', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadClient: ({ commit }, clientId) => new Promise((resolve, reject) => {
      backend.loadClient(clientId, ({ data }) => {
        commit('setClient', data)
        resolve(data)
      }, error => reject(error))
    }),
    searchClient: ({ commit }, search) => new Promise((resolve, reject) => {
      backend.searchClient(search, ({ data }) => {
        commit('setClientList', data)
        resolve(data)
      }, error => reject(error))
    }),
    recover: ({ commit }, clientId) => new Promise((resolve, reject) => {
      backend.recover(
        clientId,
        ({ data }) => resolve(data),
        (error) => reject(error)
      )
    }),
    save: ({ state }) => new Promise((resolve, reject) => {
      backend.save(state.client, ({ data }) => {
        resolve(data)
      }, error => reject(error))
    }),
    delete: ({ state }) => new Promise((resolve, reject) => {
      backend.delete(state.client.id, ({ data }) => {
        resolve(data)
      }, error => reject(error))
    }),
    create: ({ state }) => new Promise((resolve, reject) => {
      backend.create(state.client, ({ data }) => {
        resolve(data)
      }, error => reject(error))
    }),
    uploadLogo: ({state, commit}, file) => new Promise((resolve, reject) => {
      backend.uploadLogo({
        client: state.client.id,
        file
      }, ({ data }) => {
        commit('updateLogoUrl', data)
        resolve(data)
      }, error => reject(error))
    }),
    resetClientLogo: ({state, commit}) => new Promise((resolve, reject) => {
      backend.resetClientLogo({
        client: state.client.id,
      }, ({ data }) => {
        commit('updateLogoUrl', data)
        resolve(data)
      }, error => reject(error))
    }),
    loadOverview: ({ commit }, clientId) => new Promise((resolve, reject) => {
      backend.loadOverview(
        clientId,
        ({ data }) => {
          commit('setOverview', data)
          resolve(data)
        }, error => reject(error))
    }),
    loadOverviewJobStats: ({ commit }, clientId) => new Promise((resolve, reject) => {
      backend.loadOverviewJobStats(
        clientId,
        ({ data }) => {
          commit('setOverviewJobStats', data)
          resolve(data)
        }, error => reject(error))
    }),
    loadOverviewLatestJobs: ({ commit }, clientId) => new Promise((resolve, reject) => {
      backend.loadOverviewLatestJobs(
        clientId,
        ({ data }) => {
          commit('setOverviewLatestJobs', data)
          resolve(data)
        }, error => reject(error))
    }),
  },

  getters: {
    clients: state => state.clients,
    clientList: state => state.clientList,
    dropdownClientList: state => state.dropdownClientList,
    client: state => state.client,
    filters: state => state.filters,
    overview: state => state.overview,
    overviewLatestJobs: state => state.overviewLatestJobs,
    overviewJobStats: state => state.overviewJobStats
  }

}

export default client