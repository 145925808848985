<template>
<base-modal wide title="New Admin">
  <loader v-bind="{ loading }">
    <form>
      <div class="columns">
        <div class="column">
          <text-input
            required
            classes="is-medium"
            left-icon="user"
            :error="$root.errors.full_name || $root.errors.last_name"
            :value="user.full_name"
            @input="fullName">
            Full Name
          </text-input>
        </div>
        <div class="column">
          <text-input
            required
            classes="is-medium"
            left-icon="envelope"
            :error="$root.errors.email"
            :value="user.email"
            @input="email">
            Email Address
          </text-input>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <intl-phone
            size="is-medium"
            required
            :number="user.profile.mobile"
            :code="user.profile.dialing_code"
            @code="dialingCode"
            @number="mobile"/>
        </div>
        <div class="column">
          <data-selector
            required
            :error="$root.errors.timezone"
            :value="user.timezone"
            :items="timezones"
            @input="updateTimezone"
            classes="is-medium"
            searchable>
            Timezone
          </data-selector>
        </div>
      </div>

      <div class="mb-4">
        <div>
          <p class="mb has-text-weight-semibold">Select which {{ Naming.Sites.toLowerCase() }} are available to this {{ Naming.User.toLowerCase() }} <span class="has-text-danger has-text-left">*</span></p>
          <server-data-selector
              searchable
              v-model="siteToAdd"
              :items="filteredSiteList"
              left-icon="building"
              value-key="id"
              label-key="name"
              :prompt-label="`Search for a ${Naming.Site}`"
              @input="addToUserSites"
              :on-type="searchSite">
          </server-data-selector>
          <div v-if="user.sites.length > 0" class="is-flex flex-wrap is-rounded-sml has-border-input p-05" style="width: 100%">
            <div style="margin: 0.25rem" class="tag is-cool-blue" v-for="site in user.sites" :key="site.id">
              <span>{{site.name}}</span>
              <icon @click="removeSite(site)" class="hover-bold" icon="times" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </loader>
  <template #footer v-if="!loading">
    <div class="columns">
      <div class="column has-text-right">
        <submit-button :working="working" @submit="store" class="is-success" left-icon="check-circle">Create {{ Naming.User }}</submit-button>
      </div>
    </div>
  </template>
</base-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Search from "@/utils/search";

export default {

  data: () => ({
    working: false,
    loading: true,
    siteToAdd: null
  }),

  beforeCreate() {
    this.$store.commit('user/clear')
    Search.searchByUri('', 'site/searchSite', this.$store, 0)
    Promise.all([
      this.$store.dispatch('list/loadTimezones'),
    ]).then(() => {
      this.loading = false
    })

  },

  methods: {
    ...mapMutations('user', [
      'name',
      'lastName',
      'fullName',
      'email',
      'password',
      'passwordConfirmation',
      'roles',
      'mobile',
      'dialingCode',
      'sites',
      'addSite',
      'updateTimezone'
    ]),
    store() {
      this.working = true
      let full_name = this.user.full_name.split(" ")
      this.name(full_name[0])
      this.lastName(full_name[1])

      this.$store.dispatch('user/storeAdmin').then(user => {
        this.working = false
        this.$toast.success(this.Convert('User Successfully Created'))
        this.$close(user)
      }).catch(error => {
        if(error.response && error.response.status === 402) {
          this.$alert('Error Processing Payment', error.response.data)
        }
        this.working = false
      })
    },
    searchSite(text) {
      Search.debouncedSearchByUri(text, 'site/searchSite', this.$store)
    },
    removeSite(site){
      this.$store.commit('user/removeSite', site.id)
    },
    addToUserSites(){
      if(this.siteToAdd){
        const site = this.siteList.find(site => site.id === this.siteToAdd)
        this.$store.commit('user/addSite', site)
        this.siteToAdd = null
      }
    }
  },

  computed: {
    ...mapGetters('user', [
      'user'
    ]),
    ...mapGetters('list', [
      'timezones'
    ]),
    ...mapGetters('company', [
      'subscription'
    ]),
    ...mapGetters('site', [
      'siteList'
    ]),
    filteredSiteList(){
      return this.siteList.filter(siteListItem => !this.user.sites.some(site => site.id === siteListItem.id))
    }
  }

}
</script>
